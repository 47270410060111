import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../components/bs/layout"
//import Image from "../components/image"
import SEO from "../../components/bs/seo"

import MainImg from "../../images/main_bg.jpg"

import shape1 from "../../images/dec/1.png"
import shape2 from "../../images/dec/2.png"
import shape3 from "../../images/dec/3.png"
import shape4 from "../../images/dec/4.png"
import shape5 from "../../images/dec/5.png"
import shape6 from "../../images/dec/6.png"
import shape7 from "../../images/dec/7.png"
import shape8 from "../../images/dec/8.png"

import product1 from "../../images/moonamar-hladno-cijedeno-ulje-corokota-crnog-kima.png"
import product2 from "../../images/moonamar-hladno-cijedeno-bundevino-bucino-ulje.png"
import product3 from "../../images/moonamar-konopljino-ulje-iz-organskog-bio-sjemena.png"
import product4 from "../../images/moonamar-hladno-cijedeno-djevicansko-laneno-ulje.png"

const LanenoUlje = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA - HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
      description="IMMMUNO NATURA hladno cijeđeno laneno ulje - prirodno ulje koje se ne izlaže nikakvom dodatnom procesiranju."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product4}
                alt="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
              />
              <div className="_info">
                <div className="_inner">
                  <span>IMMMUNO NATURA</span>
                  <div className="title">Laneno ulje</div>
                  <div className="subtitle">
                    Omega-3-šampion biljnog porijekla
                  </div>
                  <p className="content">
                    Iznimno visoka koncentracija OMEGA-3 čini ga veoma poželjnim
                    dodatkom ishrani u prevenciji oboljenja krvnih sudova, te
                    samim tim i srčanog i moždanog udara.
                  </p>
                  <p className="content">
                    Preporučljivo je kako za oralnu upotrebu tako i za
                    utrljavanje u kožu.
                  </p>
                  <div className="price">11,90 KM</div>
                </div>
                <div className="_buttonShop">
                  <a href="https://zdravo.ba/proizvod/zdravlje/ostalo/laneno-ulje-djevicansko-hladno-cijedjeno">
                    KUPI PROIZVOD
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Opis proizvoda</Tab>
                <Tab>Sastojci i upotreba</Tab>
                <Tab>Prirodni talenat</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    IMMMUNO NATURA laneno ulje se odlikuje visokim kvalitetom
                    prvenstveno zbog veoma blagog i prirodnog postupka cijeđenja
                    kojim korisne supstance sadržane u sjemenu bivaju zadržane u
                    svojoj prirodnoj formi.
                  </p>
                  <p>
                    <strong>PROCES CIJEĐENJA</strong>: hladno cijeđeno <br />{" "}
                    <strong>KVALITET</strong>: ekstra djevičansko <br />{" "}
                    <strong>VRSTA SJEMENA</strong>: Linum usitatissimum
                    <br /> <strong>NAČIN PROIZVODNJE</strong>: Prirodan bez
                    dodatnog procesiranja <br />{" "}
                    <strong>DODACI PREPARATU</strong>: bez ikakvih dodatnih
                    sastojaka, 100% laneno ulje
                    <br />
                    <strong>STABILNOST</strong>: Laneno ulje zbog svog sastava
                    bogatog nezasićenim masnim kiselinama nije stabilno pri
                    visokim temperaturama te se ne preporučuje upotreba za
                    kuhanje ni prženje.
                    <br />
                    <strong>SKLADI&Scaron;TENJE</strong>: Preporučujemo nakon
                    otvaranja skladi&scaron;titi u frižideru i upotrijebiti u
                    roku od 2 mjeseca.
                  </p>
                  <p>&nbsp;</p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="125">
                          <p>
                            <strong>Nutritivne vrijednosti</strong>
                          </p>
                        </td>
                        <td width="67">
                          <p>
                            <strong>&nbsp;na 100 g</strong>
                          </p>
                        </td>
                        <td width="81">
                          <p>
                            <strong>na 5 g</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Kalorijska vrijednost</p>
                        </td>
                        <td>
                          <p>
                            3.678 kJ
                            <br /> 879 kcal
                          </p>
                        </td>
                        <td>
                          <p>
                            183,9 kJ
                            <br /> 43,95 kcal
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Masnoće
                            <br />
                            <em>od kojih zasićene</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            99,9 g<br /> 10,0 g
                          </p>
                        </td>
                        <td>
                          <p>
                            4,99 g<br /> 0,5 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Ugljikohidrati
                            <br />
                            <em>od kojih &scaron;ećeri</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            0,0 g<br /> 0,0 g
                          </p>
                        </td>
                        <td>
                          <p>
                            0,0 g<br /> 0,0 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Bjelančevine</p>
                        </td>
                        <td>
                          <p>0,0 g</p>
                        </td>
                        <td>
                          <p>0,0 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Sol</p>
                        </td>
                        <td>
                          <p>0,0 g</p>
                        </td>
                        <td>
                          <p>0,0 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>
                    <strong>SASTAV</strong>
                  </h3>
                  <p>
                    <strong>
                      Zrelo laneno sjeme obiluje esencijalnim nezasićenim masnim
                      kiselinama (u pro&scaron;losti nazivane vitaminom-F),
                      najvećim dijelom linolnom i linoleinskom kiselinom, zatim
                      proteinima, vitaminima, te supstancama sličnim hormonima
                      &ndash; lignanima.
                    </strong>
                    &nbsp;
                  </p>
                  <p>
                    Laneno sjeme je veoma kalorično i samim tim i hranjivo. Ima
                    sličnu kaloričnu vrijednost ora&scaron;astim plodovima
                    (orah, lje&scaron;njak, badem, kikiriki &hellip;), 100 g
                    sjemena ima vrijednostih nekih ̴600 kcal.&nbsp;
                  </p>
                  <p>
                    <strong>
                      Laneno sjeme je jedan od najbogatijih biljnih izvora
                      OMEGA-3 masnih kiselina
                    </strong>
                    &nbsp;(sadrži ih čak i do 20 puta vi&scaron;e od morske
                    ribe), koje su nadaleko slavne zbog svog vi&scaron;estrukog
                    blagotvornog dejstva, prije svega u pogledu povi&scaron;enih
                    masnoća u krvi i aterosklerotičnih poremećaja, koji mogu
                    uzrokovati moždani i srčani udar. Iz tog razloga laneno
                    sjeme ili hladno cijeđeno ulje, kod osoba bez metaboličkih
                    poremećaja u procesu stvaranja EPA i DHA, u prehrani može
                    zamijeniti ribu!
                  </p>
                  <p>
                    I proteini su zastupljeni velikim dijelom, čak do 25 %
                    ukupnog sadržaja.&nbsp;
                    <strong>
                      Minerali su zastupljeni sa nekih 5 % a radi se o uglavnom
                      o mineralima koji često manjkaju u svakodnevnoj ishrani
                      kao &scaron;to su magnezij, kalcij, bakar, natrij, fosfor,
                      selen, cink i željezo.
                    </strong>
                    &nbsp;
                  </p>
                  <p>
                    Sjeme također sadrži i, za probavu veoma bitne vlaknaste
                    materije (nekih 11%) kao i vitamine B, C, E te fitosterole.
                  </p>
                  <p>
                    Kao i kod većine prirodnih proizvoda, IMMMUNO NATURA laneno
                    ulje je potrebno redovno konzumirati, a prve pozitivne
                    efekte na zdravlje možete očekivati, nakon 4 do 8 sedmica
                    redovne upotrebe.
                  </p>
                  <h3>
                    <strong>UPOTREBA</strong>
                  </h3>
                  <p>
                    U upotrebi su kako sjemenke lana tako i laneno ulje. U
                    slučaju kori&scaron;tenja lanenog sjemena potrebno ga je
                    dobro sažvakati ili jo&scaron; bolje samljeti neposredno
                    prije upotrebe.&nbsp;
                  </p>
                  <p>
                    Oralna upotreba: preventivno se preporučuje uzimanje 1
                    supene ka&scaron;ike dnevno, a u akutnim slučajevima se mogu
                    uzimati 2-3 supene ka&scaron;ike dnevno.
                  </p>
                  <p>
                    Lokalna upotreba: utrljajte ulje u kožu i pustite ga da
                    djeluje minimalno pola sata.
                  </p>
                  <p>
                    <strong>Budwig-ova dijeta</strong>
                    <br /> U dana&scaron;nje vrijeme su recepture Dr. Budwig
                    poznate diljem svijeta i postoji veoma mnogo izvora
                    (internet, knjige &hellip;) iz kojih se možete informisati o
                    istima. Obzirom da ova dijeta nema sporednih efekata a
                    zasigurno njome unosite mnogobrojne korisne supstance u
                    organizam, možete je koristiti u najmanju ruku kao potporu
                    organizmu i drugim terapijama pri liječenju.
                  </p>
                  <p>
                    Dnevna količina koju Budwigova preporučuje je 40 do 50 grama
                    lanenog ulja pomije&scaron;anog sa 100 grama svježeg
                    nemasnog sira dnevno. Ova mje&scaron;avina se može uzeti
                    odjednom ili rasporediti u vi&scaron;e obroka. Ukoliko se
                    odlučite za vi&scaron;e obroka, preporučljivo je da svaki
                    put ponovo pripremite smjesu, jer stajanjem postoji
                    mogućnost oksidiranja i gubljenja blagotvornih svojstva.
                    <br /> Ako ne volite sir, možete koristiti i jogurt ili
                    kefir, ali njih treba staviti tri puta vi&scaron;e. Ulje i
                    sir morate jako dobro pomije&scaron;ati u homogenu smjesu.
                    Ne preporučujemo upotrebu jakih miksera zbog mogućnosti
                    uni&scaron;tavanja korisnih nezasićenih masnih kiselina.
                    <br /> Za bolji okus možete dodati i malo meda ili svježeg
                    voća.
                    <br />{" "}
                    <strong>
                      Uz to, važno je jesti i svježe samljevene lanene sjemenke,
                      jer one sadrže neke sastojke kojih u ulju nema.
                    </strong>
                    <br /> Dr. Budwig uz svoju uljno-proteinsku prehranu
                    preporučuje i terapiju sunčevim zrakama, izbjegavanje stresa
                    i toksina.
                    <br /> Prehrana dr. Budwig djeluje i kao preventiva te se
                    preporučuje i zdravim ljudima koji se često izlažu naporima,
                    a i svima ostalima.
                  </p>
                  <p>
                    <strong>
                      &Scaron;ta se jo&scaron; navodi kao bitno kod Budwigovog
                      protokola?
                    </strong>
                    <br /> &bull; Jedite umjereno
                    <br /> &bull; unosite dovoljno tekućine (vode ili frisko
                    cijeđenih sokova, s tim da veći dio treba da bude voda)
                    <br /> &bull; jedite sirovo voće i povrće
                    <br /> &bull; jedite ora&scaron;aste plodove, bez prženja!
                    <br /> &bull; Izbjegavajte slatku hranu i slatka pića (osim
                    fri&scaron;ko cijeđenih, nefiltriranih sokova)!
                    <br /> &bull; Izbjegavajte crveno meso!
                    <br /> &bull; jedite integralne tjestenine umjesto onih od
                    bijelog bra&scaron;na (umjereno)
                    <br /> &bull; važan je svakodnevni boravak na suncu (do sat
                    vremena)
                    <br /> &bull; hodajte 30 do 60 minuta svaki dan
                  </p>
                  <p>
                    <strong>
                      Na temu Budwigove dijete i lanenog ulja postoji mnogo
                      članaka. Istražite ih slobodno, posavjetujte se sa
                      stručnjacima i procijenite da li Vam ova dijeta i laneno
                      ulje mogu biti od koristi.
                    </strong>
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <p>
                    Laneno ulje, zahvaljujući svom bogatom biohemijskom sastavu
                    sve če&scaron;će se upotrebljava kao dodatak ishrani,
                    zahvaljujući prije svega neuobičajeno visokom procentu
                    omega-3 nezasićenih masnih kiselina.
                  </p>
                  <p>
                    <strong>
                      Blagotvorna dejstva lana se pretežno dovode u vezu sa
                      bolestima srca, povi&scaron;enim masnoćama u krvi, visokim
                      krvnim pritiskom, depresijama, i klimakterijem kod žena.
                    </strong>
                  </p>
                  <p>
                    Jedna od najvažnijih osobina lana je da obiluje omega-3 i
                    omega-6 nezasićenim masnim kiselinama. Mediteranski način
                    ishrane se često uzima kao primjer pravilnog omjera omega-3
                    i omega-6 masnih kiselina (omjer 4:1). Laneno ulje samo po
                    sebi ima ovaj omjer Omega-3 prema Omega-6.&nbsp;
                    <strong>
                      Studije pokazuju da osobe koje prehranom unose znatne
                      količine Omega-3 alfa-linoleinske masne kiseline (kojom
                      obiluje laneno ulje) imaju manju vjerovatnoću pojave
                      fatalnog srčanog udara.
                    </strong>
                    &nbsp;Nekolicina studija ukazuje također da prehrana bogata
                    omega-3 masnim kiselinama ili suplemantacija omega-3 masnim
                    kiselinama, uključujući i suplementaciju alfa-linoleinskom
                    masnom kiselinom,&nbsp;
                    <strong>znatno smanjuje krvni pritisak</strong>&nbsp;kod
                    osoba oboljelih od hipertenzije.&nbsp;Zahvaljujući iznimno
                    visokom procentu Omega-3 u lanu, izražen je njegov pozitivan
                    utjecaj i na vrijednost&nbsp;
                    <strong>
                      holesterola, upalne procese i procese gru&scaron;anja krvi
                    </strong>
                    .
                  </p>
                  <h4>SRCE I KRVNI SUDOVI</h4>
                  <p>
                    Lan je, naročito zbog iznimno visokog sadržaja omega-3
                    nezasićenih masnih kiselina, veoma koristan kod tegoba koje
                    se tiču krvnih sudova (oboljeli krvni sudovi su uzrok
                    srčanog i moždanog udara) a naročito kao preventiva istima.
                  </p>
                  <h4>PROBAVNI ORGANI</h4>
                  <p>
                    Lanena sluz je vrlo gusta, pa na oboljeloj sluzokoži stvara
                    za&scaron;titni film i na taj način je potpuno pokriva,
                    oblaže i &scaron;titi ublažujući razne upale. Jedna od
                    najstarijih poznatih upotreba lana u medicini je liječenje
                    upala sluznice usta, želuca i crijeva kao i protiv raznih
                    katara organa za varenje. Lanena sluz je korisno sredstvo
                    protiv zatvora i nema poznatih sporednih dejstava. Sluz lana
                    olak&scaron;ava, ublažava i ubrzava či&scaron;ćenje crijeva
                    zahvaljujući svom laksantnom dejstvu.
                  </p>
                  <h4>KOŽA</h4>
                  <p>
                    Lan održava kožu vitalnijom. Preporučljiv je slučajevima
                    suhe kože, ekcema ili preosjetljivosti na sunce. Preporučuje
                    se kako vanjsko nano&scaron;enje tako i oralno konzumiranje.
                  </p>
                  <h4>DIJABETES</h4>
                  <p>
                    Lan djeluje pozitivno na stabiliziranjem razine
                    &scaron;ećera u krvi, te može smanjiti tegobe dijabetesa.
                  </p>
                  <h4>SAGORIJEVANJE MASNOĆA I REGULACIJA TJELESNE TEŽINE</h4>
                  <p>
                    <strong>
                      Masnoće iz lana mogu doprinijeti gubitku vi&scaron;ka
                      kilograma.
                    </strong>
                    &nbsp;Tvari bogate esencijalnim masnim kiselinama, kao
                    &scaron;to je lan, ubrzavaju tjelesni metabolizam
                    potpomažući izgaranje suvi&scaron;nih, nezdravih masnoća u
                    tijelu. Ukoliko jedete &ldquo;dobre&rdquo; masnoće,
                    vjerovatnije je da će va&scaron;e tijelo skladi&scaron;titi
                    upravo onoliko masti koliko mu treba. To organizam postiže
                    termogenezom &ndash; proces u kojem masne ćelije (tzv. smeđe
                    ćelije) sagorijevaju vi&scaron;e masti, uz prethodno
                    aktiviranje putem esencijalnih masnih kiselina, kao
                    &scaron;to je gama linolenska kiselina (GLK) prisutna u
                    lanenom ulju.
                  </p>
                  <p>
                    Laneno sjeme, ali ne i laneno ulje, također&nbsp;
                    <strong>
                      sadrži i veoma mnogo lignana, fitohormona sa
                      antioksidativnim i antikancerogenim osobinama
                    </strong>
                    . Lignani su hemijski spojevi slični ljudskom hormonu
                    estrogenu. U probavnom traktu se uz pomoć crijevnih
                    bakterija pretvaraju u supstance enterodiol i enterolacton,
                    kojima se pripisuje preventivno dejstvo protiv tumora.
                  </p>
                  <p>
                    <strong>
                      Zahvaljujući naučnici Dr. Johannne Budwig laneno ulje je
                      dobilo specijalno mjesto u prirodnoj medicini.
                    </strong>
                    &nbsp;Postoji veoma mnogo izvora iz kojih se možete
                    informisati o istima.
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>

    <section id="_listProduct" className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <nav className="level">
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/corokotovo-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product1}
                        alt="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                        title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/bundevino-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product2}
                        alt="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                        title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/konopljino-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product3}
                        alt="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                        title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/laneno-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product4}
                        alt="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                        title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default LanenoUlje
